import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function EcommerceDevelopmentPage(props) {
    return (
        <div className="container draft-view">
           <h1>E-commerce Development</h1>
           <p className="text-muted">The online business environment becomes highly competitive with time and for businesses to survive in such an environment, effective sales techniques must be applied. Half of the web traffic is mobile nowadays, and that is why at Wescale, we make it a priority to optimize websites for mobile devices. When creating an online store, we study your specific business needs and your target audience. Then we design the most appropriate and convenient interface that enhances confidence in the site and improves conversion.</p>
           <p className="text-muted">The design and functionality of your eCommerce website design could make or break conversions and affect your bottom line. An eCommerce site has several layers of functionality that aren’t on standard websites, including a web server to host a storefront, payment processing, an inventory database, and a complicated dispatch system used to send products.</p>
           <p className="text-muted">Wescale has designed top quality eCommerce sites for more than a decade. We partner with clients to develop a high-functioning eCommerce website that drives sales quickly, easily, and efficiently. Learn how our digital marketing experts can help you increase ROI with a fresh, innovative eCommerce website.</p>
           <p className="text-muted"><br /></p>
           <h2>Our eCommerce website development services meet all your business needs:</h2>
           <p className="text-muted"></p>
           <h5>Your e-commerce sites will be unique</h5>
           <p className="text-muted">Our experts study your business and build your e-commerce site in a unique way, considering your specific needs and keeping the future in mind.</p>
           <p className="text-muted"><br /></p>
           <h5>You will grab your customers’ attention</h5>
           <p className="text-muted">In order to grab the attention of your visitors, our team of eCommerce designers creates a good-looking and easy-to-use interface that takes the users from their first click on the site to the purchase of the product.</p>
           <p className="text-muted"><br /></p>
           <h5>Optimized your website loading speed</h5>
           <p className="text-muted">Speed is everything. The period where websites took more than 5 seconds to load is now a thing of the past. Customers do not waste time on websites that have a slow loading speed. They will immediately close and move on to the next one. That is why we always make sure to optimize the website loading speed before it is published.</p>
           <p className="text-muted"><br /></p>
           <h5>You will reach more customers</h5>
           <p className="text-muted">&nbsp;The Google search engine since April 2015 has changed its algorithms, giving more credits and prioritizing websites optimized for mobile devices. Needless to say that mobile devices are widely used to access the internet, and even much more than desktops at times. We, at Wescale, made it a priority to optimize every single website for mobile devices, and thus increase the chance for our clients to reach more people.</p>
           <p className="text-muted"><br /></p>
           <h5>You will appear on top of the search result page</h5>
           <p className="text-muted">Search Engine Optimization is very important as it helps drive more traffic to your website. Our aim is to help our clients appear on top of the Search Engine Result Page (SERP). The rule is simple; the website that appears on top of the SERP is the one that gets visited first. And we have great SEO services who work hard to achieve that.</p>
           <p className="text-muted"><br /></p>
           <h5>You get your admin panel as convenient as possible</h5>
           <p className="text-muted">When working with an online store, you and your employees will have to deal with large volumes of information: deleting/adding products, correcting prices, changing currency rates, adding stocks, articles. Therefore, the admin panel should be as convenient as possible, intuitive for the content manager, marketer, and SEO specialist.</p>
           <p className="text-muted"><br /></p>
           <h2>Top 10 features included in our eCommerce website development services:</h2>
           <p className="text-muted">Also, we can be your reliable outsourcing eCommerce website development partner, that provides a full range of services for the development, consulting, support, and maintenance of websites at a reasonable price.</p>
           <ol>
              <li className="text-muted">Mobile friendly custom web design.</li>
              <li className="text-muted">Best UI\UX practice.</li>
              <li className="text-muted">Unlimited products.</li>
              <li className="text-muted">Unlimited categories.</li>
              <li className="text-muted">Payment gateway.</li>
              <li className="text-muted">Import/export functionality for products.</li>
              <li className="text-muted">Discount coupon engine.</li>
              <li className="text-muted">On-page SEO.</li>
              <li className="text-muted">Google listing.</li>
              <li className="text-muted">Unlimited technical support.</li>
           </ol>
           <p className="text-muted"><br /></p>
           <h2>What is the cost of building an eCommerce site?</h2>
           <p className="text-muted">Among business owners, there is an opinion that the development of a commercial website is very expensive. In fact, this is not true. Of course, quality products cannot be free. However, prices should be reasonable. Let’s talk a bit about what influences the cost of E-commerce website development.<br /></p>

        </div>

    );
}
